// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.


import moment from 'moment';
require("moment/locale/it")

window.jQuery = $
window.moment = moment
window.bootbox = bootbox

//require('moment-timezone')
require("@rails/ujs").start()
//require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require("bootstrap/dist/js/bootstrap.min")
require("tempusdominus-bootstrap-4")

import Stepper from 'bs-stepper'
import emailValidator from 'email-validator'

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

var stepper = false;

function track_event( name )
{
	try {
        fbq("trackCustom", name);
        gtag("event", name);
    }
    catch(err) {
    }
}

$(document).ready( function() {
	
	$('.video-button').click( function() {

		var notes = $(this).data('notes')

		track_event("contact_modal")
		track_event("start_contact")

		$('#videoModal #appointment_notes').val(notes)
		$('#videoModal #message_message').val(notes)
		$('#videoModal #call_message').val(notes)
		$('#videoModal .choose').show();
		$('#videoModal .choice-dest').hide();
		$('#videoModal').modal('show');
	});

	$('.wh-messenger-svg-call').click( function() {
		track_event("contact_call")
		track_event("start_contact")
	})

	$('.wh-messenger-svg-whatsapp').click( function() {
		track_event("contact_whatsapp")
		track_event("start_contact")
	})

	$('.wh-messenger-svg-email').click( function() {
		track_event("contact_email")
		track_event("start_contact")
	});

	$('.wh-messenger-svg-facebook').click( function() {
		track_event("contact_messenger")
		track_event("start_contact")
	});

	var toggleMenu = function() {
		$(".mobile-menu").toggleClass("visible");
		$('#dim').toggleClass('visible')
	}

	$('.hamburger').click( toggleMenu )
	$('.mobile-menu .close').click( toggleMenu )
	$('#dim').click( toggleMenu )

	$('.time-picker').datetimepicker({
		format: 'LT', 
		stepping:15,
		allowInputToggle: true,
		defaultDate: moment({ h: 9 }),
		disabledTimeIntervals: [[moment({ h: 0 }), moment({ h: 9 })], [moment({ h: 18, m: 30 }), moment({ h: 24 })]]
	});
	$('.calendar-picker').datetimepicker({
		format: 'L', 
		minDate: "now", 
		allowInputToggle: true,
		daysOfWeekDisabled: [0]
	})

	$('#videoModal .choice').click( function(event) {
		var dest = $(this).data('destination');
		$('#videoModal .'+dest).show();
		$('#videoModal .choose').hide();
	})

	stepper = new Stepper(document.querySelector('.bs-stepper'))

	$('.bs-stepper-next').click( function() {
		stepper.next();
	})

	$('.bs-stepper-previous').click( function() {
		stepper.previous();
	})

	$('.btn-send-message').click( function() {
		var error = $('.error-message');	

		if ($('#message_name').val().length == 0)
		{
			error.html('Indica il tuo nome');
			return;
		}

		if ($('#message_email').val().length == 0)
		{
			error.html('Indica la tua email');
			return;
		}

		if (!emailValidator.validate($('#message_email').val()))
		{
			error.html('Specifica una email valida');
			return;
		}

		if ($('#message_message').val().length == 0)
		{
			error.html('Scrivici un messaggio');
			return;
		}

		if (!$("#message_privacy").is(":checked"))
		{
			error.html('Devi accettare la privacy policy');
			return;
		}

		$('#message_form').submit();
	})


	$('.btn-send-call').click( function() {
		var error = $('.error-call');	

		if ($('#call_name').val().length == 0)
		{
			error.html('Indica il tuo nome');
			return;
		}

		if ($('#call_phone').val().length == 0)
		{
			error.html('Indica il tuo numero di telefono');
			return;
		}

		if (!$("#call_privacy").is(":checked"))
		{
			error.html('Devi accettare la privacy policy');
			return;
		}

		$('#call_form').submit();
	})

	$('.bs-stepper-check-contacts').click( function() {
		var error = $('.error-check-contacts');	

		if ($('#appointment_firstname').val().length == 0)
		{
			error.html('Indica il tuo nome');
			return;
		}

		if ($('#appointment_lastname').val().length == 0)
		{
			error.html('Indica il tuo cognome');
			return;
		}

		if ($('#appointment_phone').val().length == 0)
		{
			error.html('Indica il tuo telefono');
			return;
		}

		if ($('#appointment_email').val().length == 0)
		{
			error.html('Indica la tua email');
			return;
		}

		if (!emailValidator.validate($('#appointment_email').val()))
		{
			error.html('Specifica una email valida');
			return;
		}

		if (!$("#appointment_privacy").is(":checked"))
		{
			error.html('Devi accettare la privacy policy');
			return;
		}



		$('#new_appointment').submit();
	});

	$('.bs-stepper-check-notes').click( function() {
		var error = $('.error-check-notes');	
		if ($('#appointment_notes').val().length == 0)
		{
			error.html('Dacci qualche indicazione sul tuo viaggio');
		}
		else
		{
			error.html('');
			$('.error-check-contacts').html('')	
			stepper.next();
		}
	});

	$('.bs-stepper-check-date').click( function() {
		var btn = $(this);
		var spinner = $('.spinner-check-date');
		var error = $('.error-check-date');

		var date = $('.calendar-picker').datetimepicker('viewDate');
		var time = $('.time-picker').datetimepicker('viewDate');

		date.hour( time.hour() ) 
		date.minutes( time.minutes() ) 
		
		btn.attr('disabled', true);
		spinner.removeClass('d-none');
		error.html('');


		$.ajax({
	        url: "/appointment/check_date",
	        type: "POST",
	        data: {date: date.format("YYYY-MM-DD HH:mm")},
	        dataType: "JSON",
	        success: function( data ) {
		  		btn.attr('disabled', false);
				spinner.addClass('d-none');

				if (data && data.ok)
				{
					error.html('');
					stepper.next();
					$('.error-check-notes').html('')
				}
				else
				{
					error.html("Purtroppo la data selezionata non è più disponibile")					
				}
			},

			error: function( err ) {
				btn.attr('disabled', false);
				spinner.addClass('d-none');
				error.html("Impossibile verificare la disponibilità oraria, riprova nuovamente")
			}
	    });
	})

})

